import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toObservable } from '@angular/core/rxjs-interop';
import { retry, timeout } from 'rxjs';

import { AlertService } from './alert.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BanksPseService {

  urlV2 = environment.apiUrlV2

  private listBanks = signal([])
  listBanks$ = toObservable(this.listBanks)

  private http = inject(HttpClient)
  private alertService = inject(AlertService)

  getBanks() {
    return this.http
    .get(this.urlV2.concat('/payment-process/banks'))
    .subscribe((data: any) => {
      this.listBanks.set(data.data['banks'])
    }, (error) => {
      let message = error.error?.error?.message || 'Ha ocurrido un error, intenta de nuevo mas tarde'
      this.alertService.alert('error', `${message}`)
    })
  }

}
